import {ActiveLevel, Level, PackageValidationResult, SystemConfig} from "@/types";
import jszip from "jszip";
import user from "@/store/modules/user";

export function last(arr: any[]): any {
  return arr[arr.length - 1];
}

export function first(arr: any[]): any {
  return arr && arr.length ? arr[0] : null;
}

export function download(link: string, fileName: string) {
  const a: HTMLAnchorElement = document.createElement("a");
  a.href = link;
  a.download = fileName;
  a.click();
}

export const fillAdditionalLevelFields = (l: Level | ActiveLevel, config: SystemConfig): Level | ActiveLevel => {
  const {reviewRegistrationPeriod, reviewsDuration, daysToDoHomework} = config;
  l._opened = ((!l.closed) && l.day && (l.day > 0)) || false;
  if (l.day) {
    // set a proper field if today is a registration day for the level
    l._regday = Date.now() - (new Date("2023-12-27")).getTime() > 0 && reviewRegistrationPeriod.startDay <= l.day && reviewRegistrationPeriod.finishDay > l.day;
  }
  // find an active review index (the last one for closed levels)
  if (l.closed) {
    l._activeReview = reviewsDuration.length - 1;
  } else {
    // TODO: i would better rewrite this to while l.day > acc+r loop
    [daysToDoHomework, ...reviewsDuration].reduce((acc: number, r: number, i: number): number => {
      if ((l.day || 0) <= acc + r) {
        l._activeReview = l._activeReview === -1 ? -1 : Math.max(i - 1, -1);
        return 0;
      }
      return acc + r;
    }, 0);
  }
  l._activeReview = l._activeReview === undefined ? -1 : l._activeReview;
  return l;
};


export const formatPhone = (phone: string = ""): string => {
  let p = phone.replace(/[+()\- ]+/g, "");

  if (p.startsWith("38")) {
    p = p.slice(3);
  }
  if (p[0] !== "0" && p.length > 0) {
    p = "0" + p;
  }
  return p;
};

export const inRange = (x: number, from: number, to: number): boolean => from <= x && x <= to;

export const secondsInDay = 86400;
export const endOfDay = (ts?: number): number => {
  if (!ts) {
    ts = Date.now() / 1000;
  }
  const tzOffsetSeconds = new Date().getTimezoneOffset() * 60;
  return +new Date(Math.ceil((ts - tzOffsetSeconds) / secondsInDay) * secondsInDay + tzOffsetSeconds);
};

export async function validateZipContents(file: File, level: number) {
  const zip = await jszip.loadAsync(file);
  const filesToAnalyze = Object.keys(zip.files)
    .filter((path: string): boolean => !!path.match(/\.java/));

  if (filesToAnalyze.length === 0) {
    return Promise.resolve([{errors: ["EMPTY_ARCHIVE"]}]);
  }
  const analyzedFiles = filesToAnalyze
    .filter((path: string) => !path.match(/^__MACOSX/))
    .map(async (path): Promise<PackageValidationResult> => {
      const errors: string[] = [];
      let userPackage = "";

      if (!path.match(/^src/)) {
        errors.push("WRONG_ROOT_DIR");
      } else if (!path.match(/^src\/com\/shpp\/p2p\/cs\//)) {
        errors.push("WRONG_BASE_DIRS");
      }
      const [_, username, assignment, part] = path.match(/src\/com\/shpp\/p2p\/cs\/(.+)\/(.+)\/(.+)/) || ["", "", "", ""];
      const realUsername = username.toLowerCase();
      if (!username) {
        errors.push("EMPTY_USERNAME");
      } else if (username.match(/[A-Z]/g)) {
        errors.push("UPPERCASE_USERNAME");
      } else if (["nsurname", "rshmelev"].includes(username.toLowerCase())) {
        errors.push("DEFAULT_USERNAME");
      }

      if (assignment.match(/[A-Z]/g)) {
        errors.push("UPPERCASE_ASSIGNMENT");
      } else if (!assignment.match(/assignment[1-3]/)) {
        errors.push("WRONG_ASSIGNMENT");
      }

      if (part.match(/[Aa]ssignment/)) {
        if (!part.match(/[A-Z]/g)) {
          errors.push("LOWERCASE_PART");
        } else if (!part.match(/Assignment[1-3](?:[Ee]xt|Part[1-9](?:[eE]xt.*)?)/)) {
          errors.push("WRONG_PART");
        }
      }
      const file = zip.file(path);
      if (file) {
        const fileContent = await file.async("string");
        userPackage = (fileContent.match(/(?:^|\n)\s*package\s+([^\n;]+)/) || [])[1];

        const packageThatMatchesDirs = path
          .replace(/(.+)?src\//, "")
          .replace(/\/[^/]+\.java/, "")
          .replace(/\//g, ".");

        const correctPackage = `com.shpp.p2p.cs.${realUsername}.assignment${level}`;
        if (packageThatMatchesDirs !== userPackage) {
          errors.push("PACKAGE_DONT_MATCH_DIRS");
        } else if (correctPackage !== userPackage) {
          errors.push("WRONG_PACKAGE");
        }
      }

      return {path, errors, package: userPackage};
    });

  const result = await Promise.all(analyzedFiles);
  return result;
}
